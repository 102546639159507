import { config, library, dom } from "@fortawesome/fontawesome-svg-core"
import {
  faCircleHalfStroke,
  faCircleInfo,
  faCircleXmark,
  faCopy,
  faFileExport
} from "@fortawesome/free-solid-svg-icons"

config.mutateApproach = "sync"
library.add(
  faCircleHalfStroke,
  faCircleInfo,
  faCircleXmark,
  faCopy,
  faFileExport
)
dom.watch()
