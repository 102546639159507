import "../css"
import "./fontawesome"

const metaValue = name => document.querySelector(`meta[name="${name}"]`).content
const windowDarkTheme = () => window.matchMedia("(prefers-color-scheme: dark)")

addEventListener("click", e => {
  if (e.target.closest("#toggle-theme")) {
    const html = document.querySelector("html")
    const theme = html.dataset.bsTheme == "light" ? "dark" : "light"
    html.dataset.bsTheme = theme

    fetch("/user", {
      method: "PUT",
      headers: {
        "X-CSRF-Token": metaValue("csrf-token"),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ theme })
    })
  }
})

addEventListener("turbo:load", () => {
  document.cookie = `tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}; SameSite=Lax; Path=/${location.protocol == "https:" ? "; Secure" : ""}`

  let theme = metaValue("theme")

  if (theme == "") {
    theme = windowDarkTheme().matches ? "dark" : "light"
  }

  document.querySelector("html").dataset.bsTheme = theme
})

windowDarkTheme().addEventListener("change", ({ matches }) => {
  if (metaValue("theme") == "") {
    document.querySelector("html").dataset.bsTheme = matches ? "dark" : "light"
  }
})
